@import 'abstracts/variables';
@import 'abstracts/mixins';
@import 'abstracts/functions';

@keyframes moveleft {
  0% {
    transform: translateX(80%);
  }

  100% {
    transform: translateX(-90%);
  }
}

.marqueeContainer {
  position: relative;
  overflow: hidden;
  width: 100%;

  :hover {
    animation-play-state: paused;
  }
}

.marqueeArea {
  display: inline-block;
  white-space: nowrap;
  animation: moveleft 15s linear infinite;
}

.marqueeItem {
  position: relative;
  display: inline-block;
  margin-right: rem(50);

  > div {
    white-space: nowrap;
  }
}
