@import 'abstracts/variables';
@import 'abstracts/mixins';
@import 'abstracts/functions';

.blur {
  @include desktop {
    position: fixed;
    z-index: 100;
    display: block;
    background-color: rgb(0 0 0 / 20%);
    visibility: hidden;
    opacity: 0;
    backdrop-filter: blur(#{rem(2)});
    inset: 0;

    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }

  @include desktop-md {
    transition: backdrop-filter 0.2s, visibility 0.2s, opacity 0.2s;
  }

  @include desktop-l-and-bigger {
    transition: backdrop-filter 0.2s ease-in-out 0.2s, visibility 0.2s,
      opacity 0.2s;
  }
}

.fakeHeader {
  @include mobile {
    height: 71px;
  }

  @include desktop-md {
    height: rem(68);
  }

  @include desktop-l-and-bigger {
    height: rem(101);
  }

  visibility: hidden;
}

.fakeHeaderWithInfoBanner {
  @include mobile {
    height: rem(106);
  }

  @include desktop-md {
    height: rem(103);
  }

  @include desktop-l-and-bigger {
    height: rem(135);
  }

  visibility: hidden;
}

.header {
  @include desktop-l-and-smaller {
    &::after {
      content: '';
      z-index: 1;
      display: block;
      width: 100%;
      height: rem(8);
      background-color: $gray-light-2;
      box-shadow: 0 rem(2) rem(4) $dark-2;
    }
  }

  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  background-color: $light;
}

.headerWithBanner {
  top: rem(35);
}

.headerOpen {
  @include desktop-l-and-smaller {
    display: flex;
    flex-direction: column;
  }

  @include mobile {
    height: 100vh;

    &::after {
      display: none;
    }
  }

  @include desktop-md {
    overflow: unset;
  }
}

.top {
  @include mobile {
    padding-top: rem(18);
  }

  @include desktop {
    padding-top: rem(5);
    padding-bottom: rem(8);
  }

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.topTxt {
  @include mobile {
    margin: 0 rem(12);
    font-size: rem(11);
    line-height: rem(11);
    text-align: right;
    text-transform: uppercase;
  }

  @include desktop {
    font-size: rem(12);
    text-transform: uppercase;
  }

  @include desktop-md {
    margin-right: rem(32);
  }

  @include desktop-l-and-bigger {
    &:first-of-type {
      margin-right: rem(20);
    }
  }

  margin-bottom: 0;
  color: $brown;
  font-weight: unset;
}

.topContainer {
  display: flex;
}

.topWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mainLogo {
  @include mobile {
    top: rem(4);
    min-width: rem(134);
    max-width: rem(134);
    margin-right: rem(7);

    &::after {
      top: rem(1);
      left: rem(19);
    }
  }

  @include desktop {
    top: rem(18);
    max-height: rem(78);

    &::after {
      top: 0;
      left: rem(21);
    }
  }

  position: relative;
  z-index: 2;
  display: block;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    display: block;
    width: rem(50);
    height: rem(50);
    border-radius: 13%;
    background-color: $light;
    transform: rotate(45deg);
  }
}

.headerGarage .mainLogo {
  @include mobile {
    min-width: auto;

    &::after {
      top: rem(-3);
      left: rem(18);
    }
  }

  @include desktop {
    &::after {
      left: rem(28);
      top: rem(9);
    }
  }

  margin-right: rem(5);
}

.homeLink {
  @include mobile {
    width: rem(134);
    height: rem(45);
  }

  @include desktop {
    width: rem(142);
    height: rem(47);
  }

  position: relative;
  display: block;
}

.headerGarage .homeLink {
  @include mobile {
    height: rem(43);
    width: rem(86);
  }

  @include desktop {
    height: rem(54);
    width: rem(106);
  }
}

.garageInfos {
  @include mobile {
    padding-top: rem(5);
  }

  @include desktop {
    padding-top: rem(15);
  }

  margin-bottom: rem(7);
}

.garageWrapper {
  display: flex;
  align-items: center;
}

.garageType {
  @include mobile {
    font-size: rem(10);
    line-height: rem(12);
  }

  @include desktop {
    font-weight: 500;
    font-size: rem(14);
    line-height: rem(20);
  }

  margin-right: rem(5);
  color: $red;
}

.garageExpert {
  @include mobile {
    width: rem(52);
    height: rem(12);
  }

  @include desktop {
    width: rem(60);
    height: rem(14);
  }

  position: relative;
}

.garageName {
  @include mobile {
    overflow: hidden;
    max-width: 58vw;
    font-weight: 700;
    font-size: rem(11);
    line-height: rem(19);
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @include desktop {
    font-size: rem(14);
    font-weight: 700;
    line-height: rem(20);
  }

  display: block;
  color: $blue;
}

.burgerIcon {
  @include mobile {
    font-size: rem(30);
    cursor: pointer;
  }

  @include desktop-md {
    font-size: rem(27);
    cursor: pointer;
  }
}

.burgerIconClose {
  @include mobile {
    fill: $light;
  }
}

.locatorIcon {
  @include desktop {
    display: inline-block;
    font-size: rem(32);
    vertical-align: middle;

    &:hover {
      color: $brown;
    }
  }
}

.headerGarage .garageFeedBacks {
  @include desktop-md {
    display: block;
    margin-right: rem(10);
  }

  display: none;
}

@include mobile {
  .headerOpen .topWrapper {
    position: relative;
  }

  .headerOpen .topWrapper::before,
  .headerOpen .topWrapper::after {
    content: '';
    position: absolute;
    bottom: rem(-10);
    z-index: 0;
    display: block;
  }

  .headerOpen .topWrapper::before {
    left: rem(-61);
    width: 0;
    height: 0;
    border-bottom: rem(43) solid $brown;
    border-left: rem(48) solid transparent;
  }

  .headerOpen .topWrapper::after {
    right: rem(-15);
    width: rem(63);
    height: rem(46);
    border-top-left-radius: 20%;
    background-color: $brown;
  }

  .headerOpen .burgerIcon {
    z-index: 1;
    color: $light;
  }

  .headerOpen .topTxt,
  .headerGarage .topTxt {
    display: none;
  }

  .headerGarage.headerOpen .garageInfos {
    display: none;
  }
}

.stickyInfoBanner {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: rem(35);
  color: $light;
  line-height: rem(35);
  text-align: center;
  background-color: $blue;

  a {
    color: $light !important;
  }
}
