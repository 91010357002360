@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.content {
  font-weight: 500;
  white-space: normal;

  p {
    padding: 0;
    margin: 0 0 rem(34) 0;
    font-weight: 500;
    font-size: rem(14);
    line-height: rem(17);
    white-space: normal;
  }

  ul {
    margin: 0 0 rem(34) 0;

    li {
      margin-bottom: rem(24);
      white-space: normal;
      list-style: unset;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: left;
    text-transform: uppercase;
  }

  h1 {
    @include mobile {
      font-size: rem(22);
      line-height: rem(27);
    }

    color: $brown;
    font-weight: 700;
    font-size: rem(28);
    line-height: rem(33);
  }

  h2 {
    @include mobile {
      font-size: rem(18);
    }

    margin-bottom: rem(24);
    color: $red;
    font-weight: 700;
    font-size: rem(22);
    line-height: rem(27);
  }

  h3 {
    @include mobile {
      font-size: rem(16);
      line-height: rem(19);
    }

    color: $brown;
    font-weight: 700;
    font-size: rem(20);
    line-height: rem(24);
  }

  h4 {
    color: $brown-light;
    font-weight: 500;
    font-size: rem(16);
    line-height: rem(19);
  }

  h5 {
    color: $dark-1;
    font-weight: 500;
    font-size: rem(14);
    line-height: rem(17);
  }

  iframe {
    @include mobile {
      width: 100%;
    }
  }
}
