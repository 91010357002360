@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.footer {
  @include mobile {
    padding-top: rem(32);
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: rem(52);
  background: $brown;
}

.wrapper {
  @include mobile {
    width: rem(290);
  }

  width: rem(726);
}

.separator {
  width: 100%;
  border-top: rem(1) solid $light;
}

.uniqueSeparator {
  @include desktop-md-and-bigger {
    display: none !important;
  }

  display: block !important;
}

.separatorList {
  max-width: rem(190);
  margin: rem(7) 0;
}

.separatorList:last-child {
  max-width: 100%;
}

.links {
  list-style: none;
}

.linksItem {
  @include mobile {
    margin: 8px 0;
    line-height: 1.2em;
  }

  display: block;
  padding: rem(3) rem(14);
  padding-left: 0;
  color: $light;
  font-weight: 500;
  text-transform: uppercase;
}

.linksItem:link {
  color: $gray-light;
}

.linksItem:hover {
  color: $gray-light;
}

.title {
  @include mobile {
    display: flex;
    align-items: center;
    line-height: rem(19);
    font-weight: 500;
    gap: rem(2);
  }

  @include desktop {
    line-height: rem(24);
  }

  margin-bottom: rem(7);
  color: $light;
  font-weight: 500;
  font-size: rem(14);
  text-align: left;
  text-transform: uppercase;
}

.cities {
  padding-right: rem(15);
  padding-left: rem(15);
}

.citiesLink {
  @include mobile {
    padding: 0;
    margin: unset;
  }

  display: block;
  padding: rem(6) 0;
  margin-top: rem(2.7);
  color: $light;
  text-transform: unset;
}

.citiesLink:hover {
  color: $gray-light;
}

.citiesTitle {
  @include mobile {
    padding: rem(9) 0;
  }

  margin-bottom: rem(7);
  color: $light;
  font-size: rem(14);
  line-height: 0;
  text-align: left;
  text-transform: initial;
}

.mentions {
  @include mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: rem(20) 0;
    margin: 0;
    gap: rem(10);
  }

  margin-top: rem(48);
  margin-bottom: rem(20);
}

.mentionsLink {
  padding: 0 !important;
  border: 0 !important;
  color: $light !important;
  font-size: rem(13) !important;
  line-height: rem(19.5) !important;
  background-color: transparent;
  outline: 0;
  cursor: pointer;

  &:hover {
    background-color: transparent !important;
  }
}

.showMore {
  display: flex;
  align-items: center;
  border: 0;
  text-decoration: underline;
  color: $light;
  font-size: rem(13);
  cursor: pointer;
  gap: rem(2);
}

.hide {
  display: none !important;
}

.copyright {
  padding: 0;
  border: 0;
  text-align: center;
  color: $light;
  font-size: rem(13) !important;
  outline: 0;
}

.separatorDash {
  @include mobile {
    display: none;
  }
}
