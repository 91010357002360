@import 'abstracts/variables';
@import 'abstracts/mixins';
@import 'abstracts/functions';

.menu {
  @include mobile {
    top: rem(64);
    left: 100%;
    width: 100%;
    height: 100%;
    transition: left 0.2s;
  }

  @include desktop-l-and-smaller {
    position: fixed;
    background-color: $light;
  }

  @include desktop-md {
    top: rem(103);
    right: rem(-450);
    width: rem(450);
    transition: right 0.2s;
  }

  @include desktop-l-and-bigger {
    position: relative;
  }
}

.menuWithBanner {
  @include mobile {
    top: rem(99) !important;
  }
}

.menuOpen {
  @include mobile {
    left: 0;
    display: flex;
    flex-direction: column;
    transition: left 0.2s;
  }

  @include desktop-md {
    right: 0;
    transition: right 0.2s;
  }
}

.items {
  @include desktop-l-and-smaller {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: rem(15);
    background-color: $light;
  }

  @include desktop-l-and-bigger {
    display: flex;
    padding-right: rem(15);
    padding-left: 8%;
    background-color: $gray-light-2;
  }

  @include desktop-l {
    padding-left: 5%;
  }

  margin-bottom: 0;
}

.feedBack,
.noFeedBack {
  @include desktop-l-and-smaller {
    display: none;
  }

  @include desktop {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  @include desktop-l {
    display: flex;
  }
}

.noFeedBack {
  @include desktop {
    display: flex;
    align-items: center;
  }
}

.items,
.cardSublist,
.adviceSublist {
  @include mobile-s {
    height: calc(100vh - #{rem(160)});
  }

  @include mobile-xs {
    height: calc(100vh - #{rem(225)});
  }
}

.itemsNotVisible {
  @include desktop-l-and-smaller {
    overflow: hidden;
  }
}

.itemTitle {
  @include desktop-l-and-smaller {
    margin-bottom: rem(20);
    font-weight: 500;
    font-size: rem(16);
    line-height: rem(19);
  }

  @include desktop-l-and-bigger {
    margin-bottom: rem(26);
    font-weight: 700;
  }

  color: $red;
}

.itemList {
  @include desktop-l-and-bigger {
    columns: 2;
  }

  padding-left: 0;
  margin-bottom: rem(19);
}

.guidesList {
  @include desktop-l-and-bigger {
    columns: 3;
  }

  padding-left: 0;
  margin-bottom: rem(19);
}

.listIcon {
  display: inline-block;
  margin-right: rem(10);
  font-size: rem(25);
  vertical-align: middle;
  fill: $red;
}

.listIcon,
.listEntry {
  vertical-align: middle;
}

.listLink {
  @include desktop-l-and-smaller {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @include line-clamp(1);

  color: $brown;
}

.listLink:hover {
  color: $brown;
}

.adviceHeader {
  @include desktop-l-and-smaller {
    margin-bottom: rem(50);
  }

  @include desktop-l-and-bigger {
    margin-right: rem(89);
  }

  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}

.adviceTitle {
  @include desktop-l-and-smaller {
    margin-bottom: rem(21);
  }

  @include desktop-l-and-bigger {
    margin-bottom: rem(16);
  }

  color: $red;
  font-weight: 700;
  font-size: rem(17);
  line-height: rem(20);
  text-transform: uppercase;
}

.adviceCategories {
  @include desktop-l-and-smaller {
    padding-left: rem(58);
  }

  @include desktop-l-and-bigger {
    position: relative;
    padding-right: rem(39);
  }

  padding-left: 0;
}

.adviceCat {
  @include desktop-l-and-smaller {
    margin-bottom: rem(19);
  }

  @include desktop-l-and-bigger {
    margin-bottom: rem(20);
  }
}

.adviceItemUnderlined {
  @include desktop {
    &:hover {
      text-decoration: underline;
    }
  }

  text-decoration: underline;
}

.cards {
  @include desktop-l-and-bigger {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  padding-left: 0;
}

.cardCat {
  @include desktop-l-and-smaller {
    flex-direction: column;
  }

  @include desktop-l-and-bigger {
    align-items: center;
  }

  display: flex;
}

.cardPicture {
  @include desktop-l-and-smaller {
    position: absolute !important;
    left: rem(-40) !important;
    max-width: none !important;
    width: auto !important;
  }

  @include desktop-l-and-bigger {
    max-width: 100%;
  }

  border-radius: rem(5);
}

.cardIcon {
  @include desktop-l-and-bigger {
    margin-right: rem(6);
  }

  font-size: rem(45);
}

.cardTitle {
  @include desktop-l-and-smaller {
    font-size: rem(16);
  }

  color: $dark-1;
  font-weight: 700;
  line-height: rem(17);
  text-transform: uppercase;
}

.cardDesc {
  @include desktop-l-and-bigger {
    margin-bottom: rem(9);
  }

  color: $dark-1;
  font-size: rem(13);
  line-height: rem(15);
}

.cardLink {
  @include desktop-l-and-smaller {
    font-size: rem(16);
  }

  display: flex;
  align-items: baseline;
  color: $brown-light;
}

.accessTitle {
  @include desktop-l-and-smaller {
    margin-bottom: rem(20);
  }

  @include desktop-l-and-bigger {
    margin-bottom: rem(10);
  }

  color: $dark-1;
  font-weight: 700;
  line-height: rem(17);
  text-transform: uppercase;
}

.accessItems {
  @include desktop-l-and-smaller {
    columns: 2;
  }

  padding-left: 0;
}

.accessLink {
  @include desktop-l-and-smaller {
    display: flex;
    align-items: center;
  }

  @include desktop-l-and-bigger {
    &:hover,
    &:active,
    &:focus {
      color: $brown;
      font-weight: 500;
    }
  }

  color: $brown;
  font-weight: 500;
}

.btns {
  @include desktop-l-and-smaller {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: rem(28);
    padding-bottom: rem(4);
    background-color: $brown;
  }
}

.btnsItem {
  @include desktop-l-and-smaller {
    padding: rem(12) rem(15) !important;
    border-radius: rem(40) !important;
    margin: 0 auto rem(10);
    font-weight: 700;
    font-size: rem(16);
  }
}

.btnsItemIcon {
  display: inline-block;
  vertical-align: middle;
  fill: $light;
}

.btnsItemWhite {
  @include desktop-l-and-smaller {
    border-color: $light;
    color: $red;
    background-color: $light;
  }
}

.btnsItemWhiteIcon {
  display: inline-block;
  vertical-align: middle;
  fill: $red;
}

.itemContainer {
  @include desktop-l-and-smaller {
    background-color: $light;
  }

  @include desktop-l-and-bigger {
    display: flex;
    justify-content: space-between;
    width: rem(1130);
    padding-top: rem(47);
    padding-bottom: rem(20);
    margin: 0 auto;
  }
}

.itemContainer,
.adviceContainer {
  @include desktop-l-and-smaller {
    overflow-y: auto;
    height: calc(100% - #{rem(98)});
  }
}

.entry {
  @include desktop-l-and-smaller {
    display: flex;
    align-items: center;
    padding: rem(3) rem(4) rem(3);
    border-bottom: rem(2) solid $gray-medium;
  }

  @include desktop-l-and-bigger {
    display: block;
    padding: rem(10) rem(12);
    color: $brown;
    cursor: pointer;
    transition: background-color 0.5s;

    &:hover {
      color: $brown;
    }

    &::before {
      content: attr(title);
      overflow: hidden;
      display: block;
      height: 0;
      font-weight: 500;
      visibility: hidden;
    }
  }
}

.itemIcon {
  @include desktop-l-and-smaller {
    margin-right: rem(16);
    font-size: rem(50);
    fill: $red;
  }
}

.itemDesc {
  @include desktop-l-and-smaller {
    color: $brown;
    font-weight: 500;
    font-size: rem(16);
  }
}

.itemArrow {
  @include desktop-l-and-smaller {
    margin-left: auto;
    color: $brown;
    font-weight: 700;
    font-size: rem(20);
    fill: $brown;
  }
}

.entryRed {
  @include desktop-l-and-smaller {
    border: 0;
    background-color: $red;

    .itemDesc {
      font-weight: 700;
    }

    .itemIcon,
    .itemArrow,
    .itemDesc {
      color: $light;
      fill: $light;
    }
  }

  @include desktop-l-and-bigger {
    color: $light;
    font-weight: 500;
    background-color: $red;

    &:hover,
    &:focus,
    &:active {
      color: $light !important;
      background-color: $brown !important;
    }
  }
}

.submenu {
  @include desktop-l-and-smaller {
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    background-color: $light;
    transition: left 0.2s;
  }

  @include desktop-l-and-bigger {
    position: absolute;
    top: 100%;
    left: 0;
    overflow: hidden;
    display: flex;
    width: 100%;
    max-height: 0;
    background-color: $light;
    transition: max-height 0.3s ease-in-out;
  }
}

.submenuHeader {
  @include desktop-l-and-smaller {
    display: flex;
    align-items: center;
    padding-top: rem(14);
    padding-bottom: rem(14);
    margin-bottom: rem(20);

    .itemArrow {
      margin-right: rem(18);
      margin-left: 0;
    }
  }
}

.listItem {
  @include desktop-l-and-smaller {
    padding-top: rem(10);
    padding-bottom: rem(10);
    border-top: rem(1) solid $gray-medium;
  }

  @include desktop-l-and-bigger {
    margin-right: rem(20);
    margin-bottom: rem(7);
  }
}

.listEntry {
  @include desktop-l-and-smaller {
    color: $brown;
    font-size: rem(16);
  }
}

.listArrow {
  @include desktop-l-and-smaller {
    margin-left: auto;
    font-weight: 700;
    font-size: rem(12);
  }

  @include desktop-l-and-bigger {
    display: none;
  }
}

.listEntryEnhanced {
  @include desktop-l-and-smaller {
    font-weight: 700;
  }

  @include desktop-l-and-bigger {
    text-decoration: underline;
  }
}

.adviceContainer {
  @include desktop-l-and-smaller {
    width: 100%;
    margin: 0 auto;
  }

  @include desktop-l-and-bigger {
    padding: 0 0 rem(30) rem(9);
    background-color: $gray-light-4;
  }
}

.wrapperContent {
  @include desktop-l-and-smaller {
    max-width: rem(305);
    margin: auto;
  }

  @include desktop-l-and-bigger {
    display: flex;
  }
}

.adviceImg {
  @include desktop-l-and-smaller {
    height: rem(140);
    width: rem(118);
    margin-right: rem(10);
  }

  @include desktop-l-and-bigger {
    width: rem(173);
    height: rem(203.2);
  }

  position: relative;
  flex: none;
}

.adviceLink {
  @include desktop-l-and-smaller {
    display: block;
  }

  @include desktop-l-and-bigger {
    align-self: flex-start;
  }
}

.adviceLegend {
  @include desktop-l-and-smaller {
    padding-top: rem(30);
  }

  @include desktop-l-and-bigger {
    display: flex;
    flex-direction: column;
    max-width: rem(200);
  }
}

.adviceEntry {
  @include desktop-l-and-smaller {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @include desktop-l-and-bigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: rem(153);
    color: $dark-1;
    cursor: pointer;
  }
}

.adviceIcon {
  @include desktop-l-and-smaller {
    font-weight: 700;
    font-size: rem(17);
  }
}

.adviceSublist {
  @include desktop-l-and-smaller {
    position: absolute;
    top: 0;
    left: 100%;
    overflow-y: auto;
    width: 100%;
    background-color: $light;
    transition: left 0.2s;
  }
}

.adviceItem {
  @include desktop-l-and-smaller {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $dark-1;
  }

  @include desktop {
    line-height: rem(17);
    color: $dark-1;

    &:hover {
      color: $dark-1;
    }
  }
}

.cardSublist {
  @include desktop-l-and-smaller {
    position: absolute;
    top: 0;
    left: 100%;
    overflow-y: auto;
    background-color: $light;
    transition: left 0.2s;

    .cardContainer {
      border-bottom: initial;
    }

    .itemArrow {
      margin-right: rem(20);
    }
  }

  @include desktop-md {
    .cardContainer {
      cursor: initial;
    }
  }
}

.cardContainer {
  @include desktop-l-and-smaller {
    display: flex;
    align-items: center;
    padding-top: rem(12);
    padding-bottom: rem(12);
    border-bottom: rem(1) solid $gray-dark-2;
    margin-bottom: 0;
    transition: left 0.2s;

    .itemArrow {
      margin-left: 0;
    }
  }
}

.cardEntries {
  @include desktop-l-and-smaller {
    overflow-y: auto;
    width: calc(100% - 5rem);
    padding-left: rem(70);
  }

  @include desktop-l-and-bigger {
    padding-left: 0;
  }
}

.access {
  @include desktop-l-and-smaller {
    margin-top: rem(23);
  }

  @include desktop-l-and-bigger {
    display: flex;
    align-self: flex-start;
  }
}

@include desktop-l-and-smaller {
  .items,
  .submenuHeader,
  .itemContainer,
  .adviceList,
  .cardHeader {
    padding-right: rem(48);
    padding-left: rem(36);
  }

  .items,
  .itemContainer,
  .adviceList,
  .cardEntries,
  .adviceContainer {
    padding-bottom: rem(80);
  }

  .submenuHeader,
  .cardHeader {
    box-shadow: 0 rem(3) rem(6) $dark-2;
  }

  .subtitle,
  .uptitle {
    font-weight: 500;
  }

  .uptitle {
    line-height: rem(17);
  }

  .subtitle {
    color: $red;
    font-size: rem(18);
  }

  .submenuBtn {
    width: 100%;
    padding-top: rem(7) !important;
    padding-bottom: rem(7) !important;
    border: 0 !important;
    color: $light;
    font-size: rem(14);
    background-color: $red !important;
  }

  .adviceDesc {
    font-weight: 500;
    font-size: rem(18);
  }

  .sublistEntry {
    padding-top: rem(10);
    padding-bottom: rem(10);
    border-bottom: rem(1) solid $gray-dark;
  }

  .sublistEntry .adviceIcon {
    color: $red;
  }

  .submenuActive,
  .adviceSublistActive,
  .cardSublistActive {
    left: 0;
    z-index: 1;
    transition: left 0.2s;
  }

  .sublistDesc {
    display: block;
    max-width: 80%;
    line-height: rem(17);
  }

  .pictureWrapper {
    overflow: hidden;
    min-width: rem(134);
    max-width: rem(134);
    height: rem(90);
    border-radius: rem(5);
    margin-right: rem(21);

    > * {
      height: 100%;
    }
  }

  .cardHeader {
    display: flex;
    align-items: center;
    padding-top: rem(18);
    padding-bottom: 0;
    margin-bottom: rem(36);
  }

  .cardEntry:not(:first-of-type) {
    margin-top: rem(16);
  }

  .accessIcon {
    margin-right: rem(10);
    color: $red;
    font-weight: 700;
    fill: $red;
  }
}

@include desktop {
  .item:last-child .itemDesc {
    font-weight: 700;
  }
}

@include desktop-md {
  .itemArrow,
  .entry,
  .adviceEntry,
  .cardContainer {
    cursor: pointer;
  }
}

@include desktop-l-and-bigger {
  .entry:hover,
  .entry:focus,
  .entry:active,
  .menuHasChildren:hover .entry {
    font-weight: 500;
    background-color: $light;
    transition: background-color 0.5s;
  }

  .subMenuHovered {
    overflow-y: hidden;
    max-height: rem(528);
    transition: max-height 0.3s ease-in-out 0.1s;
  }

  .banner {
    display: flex;
    flex-direction: column;
  }

  .bannerTitle {
    margin-bottom: rem(11);
    color: $dark-1;
    font-weight: 700;
  }

  .imageContainer {
    max-width: rem(365);

    > * {
      height: 100%;
    }
  }

  .bannerImg {
    border-radius: rem(4);
  }

  .bannerBtn {
    position: relative;
    top: rem(-26);
    right: rem(10);
    align-self: flex-end;
  }

  .adviceLegend,
  .adviceCategories {
    padding-top: rem(55);
  }

  .adviceEntry,
  .adviceIcon {
    font-weight: 500;
  }

  .adviceCatActive .adviceEntry {
    color: $red;
  }

  .adviceList {
    position: absolute;
    top: 0;
    left: 100%;
    display: none;
    width: 58vw;
    padding-top: rem(27);
    padding-left: rem(43);
    background-color: $light;
  }

  .adviceCatActive .adviceList {
    display: block;
  }

  .card {
    width: rem(263);
  }

  .cardEntry {
    margin-bottom: rem(8);
  }

  .cardPicto,
  .cardItem {
    vertical-align: middle;
  }

  .pro .cards {
    width: 74%;
  }

  .separator {
    width: rem(1);
    height: auto;
    margin-top: 0;
    margin-bottom: 0;
    background-color: $brown;
  }

  .access .separator {
    margin-right: rem(47);
  }

  .accessItem {
    margin-bottom: rem(8);
    text-transform: capitalize;
  }

  .accessItem::before {
    content: '';
    display: inline-block;
    width: rem(3);
    height: rem(3);
    margin-right: rem(7);
    vertical-align: middle;
    background-color: $red;
  }

  .accessItemUppercase {
    text-transform: uppercase;
  }
}

.adviceSublist,
.cardSublist {
  @include desktop-md {
    height: 100%;
  }
}
